import { lazy } from "react";
import { createHashRouter, redirect } from "react-router-dom";

import AppErrorBoundary from "components/app/AppErrorBoundary";

const PublicApp = lazy(() => import("components/app/PublicApp"));
const ProtectedApp = lazy(() => import("components/app/ProtectedApp"));
const CreativesPreviewApp = lazy(() => import("components/app/CreativesPreviewApp"));

export const router = createHashRouter([
  {
    children: [
      {
        path: "/",
        loader: () => redirect("/dashboard"),
      },
      {
        path: "/auth/*",
        element: (
          <AppErrorBoundary key="publicApp">
            <PublicApp />
          </AppErrorBoundary>
        ),
      },
      {
        path: "/preview/*",
        element: (
          <AppErrorBoundary>
            <CreativesPreviewApp />
          </AppErrorBoundary>
        ),
      },
      {
        path: "*",
        element: (
          <AppErrorBoundary>
            <ProtectedApp />
          </AppErrorBoundary>
        ),
      },
    ],
  },
]);
